<template>
    <div>
    <component :is="component" v-if="component"  />
        <div class="m_join">
            <button class="mj_btn" @click="openAlert()">가입승인</button>
            <button class="mj_btn">가입거절</button>
        </div>
    </div>
</template>

<script>
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex"
    import vuxAlert from "@/mixins/vux-alert"
    export default {
        name: "MemberDetailPageLayout",
        mixins: [vuxAlert],
        components: {
        },
        props: {
            memberData: {
                type: Object,
                default: () => {return {}},
            },
        },
        data() {
            return {
                type: 2, //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            component() {
                if(this.type === 1) {
                    return () => import(`@/template/member/detail/MemberDetailPageMentorLayout`)
                }else if(this.type === 2) {
                    return () => import(`@/template/member/detail/MemberDetailPageMenteeLayout`)
                }else{
                    return null;
                }
            }
        },
        methods:{
            decodeHtml(html) {
                return util.htmlDecode(html);
            },
            openAlert() {
                this.createAlert({
                    title: '',
                    content: '가입승인 처리 되었습니다.',
                    btnText: '확인',
                })
            }
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>